import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Typography from './Typography'
import ActionButton from './ActionButton'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const Pagination = props => {
  const disabled = {
    prev: props.currentPage === 1,
    next: props.currentPage === props.numPages
  }
  return (
    <Root className={props.className}>
      <Container>
        <Prev
          component={disabled.prev ? undefined : Link}
          to={disabled.prev ? undefined : props.currentPage - 1 === 1 ? props.path : `${props.path}${props.currentPage - 1}/`}
          name="triangle-left"
          disabled={disabled.prev} />
        <Num variant="h5" lang="en" component="div">
          <Num_Current>{props.currentPage}</Num_Current>
          <Num_Divider>of</Num_Divider>
          <Num_Total>{props.numPages}</Num_Total>
        </Num>
        <Next
          component={disabled.next ? undefined : Link}
          to={disabled.next ? undefined : `${props.path}${props.currentPage + 1}/`}
          name="triangle-right"
          disabled={disabled.next} />
      </Container>
    </Root>
  )
}

const Root = styled.div`
`

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 360px;
  @media ${mq.and(mobile)} {
    max-width: 100%;
  }
`

const Num = styled(Typography)`
`

const Num_Current = styled.span``

const Num_Divider = styled.span`
  margin: 0 12px;
`

const Num_Total = styled.span``

const Prev = styled(ActionButton)``

const Next = styled(ActionButton)``

export default ThemeConsumer(Pagination)
